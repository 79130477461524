<template>
    <div class="sendallTeacher">
        <el-card>
            <div class="selectSchool">
                <div class="tip"></div>
                <div class="tip">
                    <el-select
                        v-model="project_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入项目名称"
                        :remote-method="remoteMethod"
                        :loading="loading">
                        <el-option
                            v-for="item in list"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div class="tip">
                    <el-select
                        v-model="group_id"
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入班级名称"
                        :remote-method="remoteMethodGroup"
                        :loading="loading">
                        <el-option
                            v-for="item in grouplist"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <div @click="getData"> 
                    <el-button class="themeButton">点击生成PDF</el-button>
                </div>
            </div>
        </el-card>
    </div>
</template>
<style lang="scss" >
    @import "@/assets/css/manager/other/sendallTeacher.scss";
</style>
<script>
    import VueUeditorWrap from "vue-ueditor-wrap";
    import { encryptCode,decryptCode } from "@/utils/function";
    import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
    import { request } from "@/request/http";
    export default {
        name: "expDetail",
        components: {
            VueUeditorWrap
        },
        data() {
            return{
                list:[],
                project_id:"",
                loading: false,
                group_id:"",
                grouplist:[],
            }
        },
        created()
        {
            let self = this;
        },
        methods:{
            remoteMethodGroup(query)
            {
                if (query !== '') 
                {
                    this.loading = true;
                    let self = this;
                    // 请求参数
                    let data = {
                        text:query
                    };
                    nprogressStart();
                    self.$Loading.show();
                    request("POST", `/index.php/backother/getGroupListBySearch`, data)
                        .then((result) => {
                            nprogressClose();
                            self.$Loading.hide();
                            self.loading = false;
                            if(result.code > 0 )
                            {
                                self.grouplist = result.list;
                                console.log(self.list)
                            }
                        })   
                } 
                else 
                {
                    this.grouplist = [];
                    this.group_id = "";
                }
            },
            remoteMethod(query) 
            {
                if (query !== '') 
                {
                    this.loading = true;
                    let self = this;
                    // 请求参数
                    let data = {
                        text:query
                    };
                    nprogressStart();
                    self.$Loading.show();
                    request("POST", `/index.php/backother/getProjectListBySearch`, data)
                        .then((result) => {
                            nprogressClose();
                            self.$Loading.hide();
                            self.loading = false;
                            if(result.code > 0 )
                            {
                                self.list = result.list;
                                console.log(self.list)
                            }
                        })   
                } 
                else 
                {
                    this.list = [];
                    this.project_id = "";
                }
            },
            /**
             * @name: 获取页码数据
            */
            getData()
            {
                let self = this;
                // 请求参数
                let data = {
                    project_id:this.project_id,
                    group_id:this.group_id,
                };
                nprogressStart();
                self.$Loading.show();
                request("POST", `/index.php/backother/createPDFByID`, data)
                    .then((result) => {
                        nprogressClose();
                        self.$Loading.hide();
                        if(result.code > 0 )
                        {
                            self.$Tips({
                                // 消息提示内容
                                message: result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType: 'warning',
                                displayTime: 1500
                            })
                        }
                    })   

            },

        }
    }
</script>